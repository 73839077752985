import React, {useRef, useState, useEffect} from 'react'
import CustomButton from '../Common/CustomButton'
import {useGSAP} from '@gsap/react'
import gsap from 'gsap'
import {SiGreensock, SiNextdotjs, SiThreedotjs} from 'react-icons/si'
import * as THREE from 'three'

gsap.registerPlugin(useGSAP)

function ParticlesGalaxyBackground() {
  const mountRef = useRef(null)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  useEffect(() => {
    if (!isMounted || !mountRef.current) return
    const mountElement = mountRef.current
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    )
    const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true})

    renderer.setSize(window.innerWidth, window.innerHeight)
    mountRef.current.appendChild(renderer.domElement)

    const geometry = new THREE.BufferGeometry()
    const particlesCount = 5000
    const posArray = new Float32Array(particlesCount * 3)

    for (let i = 0; i < particlesCount * 3; i++) {
      posArray[i] = (Math.random() - 0.5) * 5
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3))

    const material = new THREE.PointsMaterial({
      size: 0.005,
      color: 0xffffff,
    })

    const particlesMesh = new THREE.Points(geometry, material)
    scene.add(particlesMesh)

    camera.position.z = 2

    const animate = () => {
      requestAnimationFrame(animate)
      particlesMesh.rotation.x += 0.001
      particlesMesh.rotation.y += 0.001
      renderer.render(scene, camera)
    }

    animate()

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer.setSize(window.innerWidth, window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      if (mountElement) {
        mountElement.removeChild(renderer.domElement)
      }
    }
  }, [isMounted])

  return (
    <div
      ref={mountRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
      }}
    />
  )
}
function MouseTrailBackground() {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    let particles = []
    let mouse = {x: null, y: null}

    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    class Particle {
      constructor(x, y) {
        this.x = x
        this.y = y
        this.size = Math.random() * 5 + 1
        this.speedX = Math.random() * 3 - 1.5
        this.speedY = Math.random() * 3 - 1.5
      }

      update() {
        this.x += this.speedX
        this.y += this.speedY

        if (this.size > 0.2) this.size -= 0.1
      }

      draw() {
        ctx.fillStyle = 'rgba(255,255,255,0.8)'
        ctx.beginPath()
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2)
        ctx.fill()
      }
    }

    function handleParticles() {
      for (let i = 0; i < particles.length; i++) {
        particles[i].update()
        particles[i].draw()

        if (particles[i].size <= 0.3) {
          particles.splice(i, 1)
          i--
        }
      }
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      handleParticles()
      requestAnimationFrame(animate)
    }

    window.addEventListener('mousemove', function (event) {
      mouse.x = event.x
      mouse.y = event.y
      for (let i = 0; i < 5; i++) {
        particles.push(new Particle(mouse.x, mouse.y))
      }
    })

    animate()

    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    })
  }, [])

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
      }}
    />
  )
}
const GSAPBanner = () => {
  const [showGSAP, setShowGSAP] = useState(true)
  const bannerRef = useRef(null)

  useGSAP(
    () => {
      const container = bannerRef.current
      const heading = container.querySelector('h1')
      const paragraphs = container.querySelectorAll('p')
      const button = container.querySelector('button')
      const highlights = container.querySelectorAll('.highlight')

      const tl = gsap.timeline({defaults: {ease: 'power4.out'}})

      tl.from(heading, {
        opacity: 0,
        y: 50,
        duration: 1,
      })
        .from(
          paragraphs,
          {
            opacity: 0,
            y: 30,
            stagger: 0.2,
            duration: 0.8,
          },
          '-=0.5'
        )
        .from(
          button,
          {
            opacity: 0,
            y: 20,
            scale: 0.8,
            duration: 0.5,
          },
          '-=0.3'
        )
        .from(
          highlights,
          {
            fontWeight: '600',
            stagger: 0.1,
            duration: 0.3,
          },
          '-=0.2'
        )

      tl.set(button, {opacity: 1, y: 0, scale: 1}, '>')

      const buttonHover = gsap.to(button, {
        scale: 1.05,
        duration: 0.3,
        paused: true,
        ease: 'power2.out',
      })

      button.addEventListener('mouseenter', () => buttonHover.play())
      button.addEventListener('mouseleave', () => buttonHover.reverse())
    },
    {scope: bannerRef}
  )

  return (
    <section
      className={`${showGSAP ? 'new-banner' : 'new-banner-hidden'}`}
      id='new-banner'
      ref={bannerRef}
    >
      <MouseTrailBackground />
      <ParticlesGalaxyBackground />
      <div className='container'>
        <h1>
          I'm Kirankumar <br /> Creative Frontend Developer.
        </h1>
        <p>
          I develop <span className='highlight accessible'>accessible</span>,{' '}
          <span className='highlight responsive'>responsive</span>,{' '}
          <span className='highlight interactive'>interactive</span>, and{' '}
          <span className='highlight animated'>animated</span> websites with a
          strong focus on{' '}
          <span className='highlight performance'>performance</span>.
        </p>
        <p>
          I'm revamping my website with <SiNextdotjs className='icon' />
          <SiThreedotjs className='icon' /> <SiGreensock className='icon' />
        </p>
        <CustomButton
          buttonName='View Old version'
          onClick={() => setShowGSAP(false)}
        />
      </div>
    </section>
  )
}

export default GSAPBanner
