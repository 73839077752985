import React from 'react'
import {AiOutlineCheck} from 'react-icons/ai'
import {IoMdPaperPlane} from 'react-icons/io'
import {ImSpinner7} from 'react-icons/im'
import swal from '@sweetalert/with-react'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm} from 'react-hook-form'
import emailjs from '@emailjs/browser'

const schema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .min(3, 'Full Name must be at least 3 characters')
    .required('Full Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phoneNumber: Yup.string().trim().required('Phone Number is required'),
  message: Yup.string().trim().required('Message is required'),
})

const confettiSound = new Audio(
  'https://www.soundjay.com/misc/sounds/whip-whoosh-02.mp3'
)

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: {errors, isSubmitting},
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const onSubmit = async (data) => {
    try {
      const response = await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        data,
        process.env.REACT_APP_PUBLIC_KEY
      )

      if (response.status === 200) {
        confettiSound.play()
        swal({
          button: false,
          timer: 6000,
          content: (
            <>
              <div className='success__message'>
                <div className='ic'>
                  <AiOutlineCheck className='ii' />
                </div>
                <h1>Dear {data?.fullName}</h1>
                <p>
                  Thank you for getting in touch! will get back in touch with
                  you soon!
                </p>
                <h5>Have a great day !</h5>
              </div>
            </>
          ),
        })
        reset()
      }
    } catch (error) {}
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' id='c_form'>
        <div className='form_control'>
          <label htmlFor='fullName'>
            Full Name <span className='warning'>*</span>
          </label>
          <input
            type='text'
            name='fullName'
            id='fullName'
            placeholder='John Doe'
            spellCheck='false'
            {...register('fullName')}
          />
          {errors.fullName && (
            <p className='error'>{errors.fullName.message}</p>
          )}
        </div>
        <div className='form_control'>
          <label htmlFor='email'>
            Email <span className='warning'>*</span>
          </label>
          <input
            id='email'
            type='email'
            name='email'
            placeholder='hire@gmail.com'
            spellCheck='false'
            {...register('email')}
          />
          {errors.email && <p className='error'>{errors.email.message}</p>}
        </div>
        <div className='form_control'>
          <label htmlFor='phoneNumber'>
            Phone Number <span className='warning'>*</span>
          </label>
          <input
            id='phoneNumber'
            type='tel'
            name='phoneNumber'
            placeholder='7569984640'
            spellCheck='false'
            {...register('phoneNumber')}
          />
          {errors.phoneNumber && (
            <p className='error'>{errors.phoneNumber.message}</p>
          )}
        </div>
        <div className='form_control'>
          <label htmlFor='message'>
            Message <span className='warning'>*</span>
          </label>
          <textarea
            id='message'
            name='message'
            placeholder='Congratulations 👏'
            spellCheck='true'
            {...register('message')}
          />
          {errors.message && <p className='error'>{errors.message.message}</p>}
        </div>
        <button
          type='submit'
          className={isSubmitting ? 'hire_btn_disabled' : 'hire_btn'}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <ImSpinner7 className='spinner' />
          ) : (
            <span>
              Send <IoMdPaperPlane className='hire_btn_icon' />
            </span>
          )}
        </button>
      </form>
      {/* <ToastContainer /> */}
    </>
  )
}

export default ContactForm
