import React, {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {CgArrowLongLeft} from 'react-icons/cg'

// data
import data from '../Data/data'

function NotFound() {
  useEffect(() => {
    document.title = data.kirankumargonti.websiteInformation.notFoundTitle
  }, [])

  return (
    <div className='notfound'>
      <div className='container'>
        <div className='notfound_content'>
          <Link to='/'>
            <img src={data.kirankumargonti.websiteInformation.logo} alt='' />
          </Link>

          <h1>404 Notfound</h1>
          <h5>Holy Crap! It Looks like you lost in space come back to earth</h5>
          <Link to='/' className='back_to_home link'>
            <CgArrowLongLeft className='icon' /> Back to Earth
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound
