import React from 'react'

const ProgressBar = () => {
  const scrollIndicator = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = (winScroll / height) * 100

    document.getElementById('progress-bar').style.width = scrolled + '%'
  }
  window.onscroll = function () {
    scrollIndicator()
  }
  return <div class='progress-bar' id='progress-bar'></div>
}

export default ProgressBar
