import React from 'react';
import data from '../Data/data';
import {
  FaFacebookF,
  FaGithub,
  FaHackerrank,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaCodepen,
  FaYoutube,
} from 'react-icons/fa';

function SocialLinks() {
  const {
    facebook,
    github,
    twitter,
    instagram,
    codePen,
    youtube,
    hackerRank,
    linkedIn,
  } = data.kirankumargonti.socialLinks;
  return (
    <div className='social_links'>
      <a href={github} className='github' target='_blank' rel="noopener noreferrer">
        <FaGithub className='icon' />
      </a>
      <a href={linkedIn} className='linkedin' target='_blank' rel="noopener noreferrer">
        <FaLinkedinIn className='icon' />
      </a>
      <a href={youtube} className='youtube_link' target='_blank' rel="noopener noreferrer">
        <FaYoutube className='icon' />
      </a>
      <a href={codePen} className='codepen' target='_blank' rel="noopener noreferrer">
        <FaCodepen className='icon' />
      </a>
      <a href={instagram} className='instagram' target='_blank' rel="noopener noreferrer">
        <FaInstagram className='icon' />
      </a>
      <a href={facebook} className='facebook' target='_blank' rel="noopener noreferrer">
        <FaFacebookF className='icon' />
      </a>
      <a href={hackerRank} className='hackerrank' target='_blank' rel="noopener noreferrer">
        <FaHackerrank className='icon' />
      </a>
      <a href={twitter} className='twitter' target='_blank' rel="noopener noreferrer">
        <FaTwitter className='icon' />
      </a>
    </div>
  );
}

export default SocialLinks;
