import React from 'react'
import Banner from '../Components/Banner'
import About from '../Components/About'
import Contact from '../Components/Contact'
import Youtube from '../Components/Youtube'
import Timeline from '../Components/Timeline'
import GSAPBanner from '../Components/GSAPBanner'

import {Helmet} from 'react-helmet'

// data
import data from '../Data/data'
import Skills from '../Components/Skills'
import ScrollAnimations from '../Components/ScrollAnimations'

function Home() {
  const title = data.kirankumargonti.websiteInformation.homeTitle

  return (
    <>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta charSet='UTF-8' />
        <meta http-equiv='content-language' content='en' />
        <meta http-equiv='content-type' content='text/html; charset=utf-8' />
        <link rel='canonical' href='https://kirankumargonti.com' />
        <meta name='author' content='Kirankumar Gonti' />
        <title>{title}</title>
        <meta
          name='description'
          content="Tech enthusiast web developer specializing mostly in JavaScript/Node.js, Interested in Blockchain, Beginner in Solidity and I'm a YouTuber also."
        />
        <meta
          name='keywords'
          content='Kirankumar Gonti, Kiran kumar gonti, kiran, gonti, kiran website, kiran portfolio,kirankumar website, kirankumar portfolio,kirankumargonti.ga,kirankumargonti.com,kirankumar.com,kirankumar, gonti blockchain developer, web developer, react developer, youtuber'
        />
        <meta
          property='og:title'
          content='Kirankumar Gonti | Web & Blockchain Developer'
        />
        <meta
          property='og:description'
          content="Tech enthusiast web developer specializing mostly in JavaScript/Node.js, Interested in Blockchain, Beginner in Solidity and I'm a YouTuber also."
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://kirankumargonti.com' />
        <meta
          property='og:image'
          itemProp='image'
          content='./android-chrome-512x512.png'
        />
        <meta name='robots' content='index,follow' />
        <meta name='googlebot' content='index,follow' />
        <meta property='og:image:alt' content='Website Logo' />
        <meta property='og:site_name' content='Portfolio website' />
        <meta property='og:locale' content='en_US' />
        <meta property='article:author' content='Kirankumar Gonti' />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:site' content='@gontikirankumar' />
        <meta name='twitter:creator' content='Kirankumar Gonti' />
        <meta name='twitter:url' content='https://kirankumargonti.com' />
        <meta
          name='twitter:title'
          content='Kirankumar Gonti | Frontend Developer - Web3'
        />
        <meta
          name='twitter:description'
          content="Tech enthusiast Frontend developer specializing mostly in Next js and web3, Interested in Blockchain, Beginner in Solidity and I'm a YouTuber also."
        />
        <meta name='twitter:image' content='./android-chrome-512x512.png' />
        <meta name='twitter:image:alt' content='Website Logo' />
      </Helmet>
      <div>
        <ScrollAnimations />
        {/* <GSAPBanner /> */}
        <Banner />
        <About />
        <Skills />
        <Youtube />
        <Timeline />
        <Contact />
      </div>
    </>
  )
}

export default Home
