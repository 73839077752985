import React from 'react'
import {SiGithub, SiYoutube} from 'react-icons/si'

// data
import data from '../Data/data'

function Youtube() {
  const videos = data.kirankumargonti.youtubeVideos
  const youtubeURL = videos[0].youtubeURL
  const youtubeVideosGithubRepo = videos[0].youtubeVideosGithubRepo
  return (
    <section className='youtube' id='youtube'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='youtube_content'>
              <a
                href={youtubeURL}
                target='_blank'
                rel='noopener noreferrer'
                className='youtube_logo'
              >
                <SiYoutube className='icon' />
              </a>
              <h2>YouTube Videos</h2>
              <p>
                Here are a few of Videos on YouTube. Check out the channel for
                MUCH more
              </p>
              <div className='youtube_videos'>
                {videos.slice(1).map((video) => (
                  <div className='video_card' key={video.videoID}>
                    <a
                      rel='noopener noreferrer'
                      target='_blank'
                      href={video.videoURL}
                    >
                      <div className='video_thumbnail'>
                        <img src={video.videoThumbnail} alt='' />
                      </div>
                      <div className='video_title'>
                        <h4>{video.videoTitle}</h4>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
              <div className='youtube_buttons'>
                {
                  <a
                    href={youtubeURL}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='youtube_btn'
                  >
                    View More
                    <SiYoutube className='icon' />
                  </a>
                }
                {
                  <a
                    href={youtubeVideosGithubRepo}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='youtube_btn github_btn'
                  >
                    <SiGithub className='icon' /> Github Repo
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Youtube
