import React from 'react'
import SocialLinks from '../Components/SocialLinks'


// data
import data from '../Data/data'

function Footer() {
  return (
    <footer>
      <div className='container'>
        <div className='footer_links'>
          <SocialLinks />
        </div>
        <hr style={{backgroundColor: 'lightgray'}} />
        <h4>
          © Copyright {new Date().getFullYear()} &nbsp;
          {data.kirankumargonti.personalInformation.name} - All Rights Received
        </h4>
      </div>
    </footer>
  )
}

export default Footer
