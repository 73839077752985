import React from 'react'
import '../styles/Button.scss'

export const Button = ({children, variant, isDisabled, onClick}) => {
  return (
    <button
      className={`button-shadcn ${variant}`}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  )
}
