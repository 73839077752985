import React from 'react'
import ProjectLanguages from './ProjectLanguages'
import ProjectLinks from './ProjectLinks'

// data
import data from '../Data/data'

const AllProjects = () => {
  const {projects} = data.kirankumargonti

  return (
    <section className='all_projects'>
      <div className='section_title text-white'>
        <h1>My Fabulous Work</h1>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='projects_wrapper'>
              {projects?.map((project) => (
                <div className='project_card' key={project.projectID}>
                  <img src={project.projectImage} alt='' />
                  <div className='project-info'>
                    <div className='project_description'>
                      <h3> {project.projectName} </h3>
                      <p>{project.projectDescription}</p>
                    </div>
                    {project.projectLinks && (
                      <ProjectLinks links={project.projectLinks} />
                    )}
                  </div>
                  {/* <div className='project_description'>
                    <h3> {project.projectName} </h3>
                    <p>{project.projectDescription}</p>
                  </div>
                  <ProjectLanguages languages={project.projectTechStack} />
                  <ProjectLinks links={project.projectLinks} /> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AllProjects
