import React from 'react'
import {IconContext} from 'react-icons'
import {
  SiJavascript,
  SiReact,
  SiNextdotjs,
  SiNodedotjs,
  SiTailwindcss,
  SiTypescript,
  SiRedux,
  SiBootstrap,
  SiSass,
  SiHtml5,
  SiCss3,
  SiMongodb,
  SiFirebase,
  SiGit,
  SiGithub,
  SiEthereum,
  SiFigma,
  SiWeb3Dotjs,
  SiSolidity,
  SiJest,
  SiExpress,
  SiGreensock,
  SiThreedotjs,
  SiShadcnui,
  SiReactquery,
  SiStyledcomponents,
  SiEthers,
  SiWagmi,
  SiAntdesign,
  SiMui,
  SiWebpack,
} from 'react-icons/si'

const skillsData = [
  {icon: SiNextdotjs, name: 'Next.js'},
  {icon: SiReact, name: 'React'},
  {icon: SiRedux, name: 'Redux Toolkit'},
  {icon: SiReactquery, name: 'Tanstank query'},
  {icon: SiTypescript, name: 'TypeScript'},
  {icon: SiJavascript, name: 'JavaScript'},
  {icon: SiTailwindcss, name: 'Tailwind CSS'},
  {icon: SiShadcnui, name: 'Shadcn'},
  {icon: SiMui, name: 'Mui'},
  {icon: SiStyledcomponents, name: 'Styled components'},
  {icon: SiAntdesign, name: 'Ant design'},
  {icon: SiBootstrap, name: 'Bootstrap'},
  {icon: SiHtml5, name: 'HTML5'},
  {icon: SiCss3, name: 'CSS3'},
  {icon: SiSass, name: 'Sass'},
  {icon: SiJest, name: 'Jest'},
  {icon: SiGreensock, name: 'GSAP'},
  {icon: SiThreedotjs, name: 'Three.js'},
  {icon: SiWebpack, name: 'Webpack'},
  {icon: SiNodedotjs, name: 'Node.js'},
  {icon: SiMongodb, name: 'MongoDB'},
  {icon: SiExpress, name: 'Express'},
  {icon: SiFirebase, name: 'Firebase'},
  {icon: SiGithub, name: 'GitHub'},
  {icon: SiFigma, name: 'Figma'},
  {icon: SiGit, name: 'Git'},
  {icon: SiEthereum, name: 'Ethereum'},
  {icon: SiWagmi, name: 'Wagmi'},
  {icon: SiWeb3Dotjs, name: 'Web3.js'},
  {icon: SiEthers, name: 'Ethers.js'},
  {icon: SiSolidity, name: 'Solidity'},
]

function Skills() {
  return (
    <section className='skills' id='skills'>
      <div className='container'>
        <div className='section_title'>
          <h1>Skills</h1>
        </div>
        <IconContext.Provider value={{size: '3em'}}>
          <div className='skills-grid'>
            {skillsData.map((skill, index) => (
              <div key={index} className='skill-item'>
                <skill.icon />
                <span>{skill.name}</span>
              </div>
            ))}
          </div>
        </IconContext.Provider>
      </div>
    </section>
  )
}

export default Skills
