import React from 'react'

// data
import data from '../Data/data'
import {Button} from './Button'

function About() {
  const {image, fullBio, resume} = data.kirankumargonti.personalInformation
  return (
    <section className='about' id='about'>
      <div className='container'>
        <div className='section_title'>
          <h1>About me</h1>
        </div>
        <div className='swinger'>
          <img src={image} alt='Kirankumar Gonti' />
        </div>
        <p>{fullBio}</p>
        <Button variant='primary'>
          <a href={resume} download>
            Download Resume
          </a>
        </Button>
      </div>
    </section>
  )
}

export default About
