import React, { useEffect, useRef} from 'react'
import Halo from 'vanta/dist/vanta.halo.min'
import data from '../Data/data'
import {HashLink as Link} from 'react-router-hash-link'
import {Button} from './Button'
import * as THREE from 'three'

function Banner() {
  const {shortBio, designation} = data.kirankumargonti.personalInformation
  const [vantaEffect, setVantaEffect] = React.useState(0)
  const vantaRef = useRef(null)

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        Halo({
          el: vantaRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          baseColor: 0x0,
          backgroundColor: 0x0,
          amplitudeFactor: 1.7,
          xOffset: 0.12,
          yOffset: 0,
          size: 1.0,
          THREE, // pass THREE to vanta
        })
      )
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (
    <section ref={vantaRef} className='banner' id='banner'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='banner_content'>
              <h2>Hi, I'm Kirankumar</h2>
              <h1>{designation}</h1>
              <p> {shortBio} </p>
              <div className='banner_buttons'>
                <Link to='/projects' className='voltage-button'>
                  <Button variant='primary'>Explore my projects</Button>
                </Link>
                <Link to='/#contact'>
                  <Button variant='secondary'>Lets Chat</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
