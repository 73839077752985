import Resume from '../Assets/kirankumar_gonti_frontend.pdf'
import Logo from '../Assets/logo.png'
import {v4 as uuid} from 'uuid'
import you1 from '../Assets/you1.jpg'
import you2 from '../Assets/you2.jpg'
import you3 from '../Assets/you3.jpg'

const data = {
  kirankumargonti: {
    websiteInformation: {
      homeTitle: 'Kirankumar Gonti | Frontend Developer - Web3',
      projectsTitle: 'Projects | Kirankumar Gonti',
      notFoundTitle: 'Error(404 Notfound) - Kirankumar Gonti',
      logo: Logo,
    },
    personalInformation: {
      name: 'Kirankumar Gonti',
      designation: 'Frontend Developer',
      shortBio: `I develop accessible, responsive, interactive, and animated websites with a strong focus on performance`,

      // 'Full Stack BlockChain Developer specializing in ( Solidity and React) development Experienced with all stages of the development cycle for dynamic web projects. Well-versed in numerous programming languages  Python, JavaScript, and Databases NoSQL and SQL.',
      // fullBio: `A freelancer based in Hyderabad, India. I'm graduated in a bachelor technology in the computer science stream. I'm a youtube also. I make videos related to web Development and programming. I'm open to getting any work or job related to Software as a freelancer or an employee at your company. I want to be placed in a Good company where I can explore my skills and I would like to improve my talent, work quality and gain experience.I believe in hard work because it pays off on what I am doing. I am flexible in doing multiple tasks`,
      fullBio: `I'm a passionate frontend developer creating responsive, accessible, and high-performance web applications. I specialize in JavaScript, React, and Next.js, with a strong focus on optimizing user experiences and integrating modern technologies like blockchain. I've led teams, mentored developers, and delivered cutting-edge solutions across various industries, including e-commerce, DeFi, and NFT marketplaces. My goal is to build efficient, scalable software while continuously exploring innovative technologies to push the boundaries of web development.`,
      image:
        'https://ik.imagekit.io/kirankumargonti/kkk_fdZva2JOr.jpg?updatedAt=1726125002099',
      resume: Resume,
      /* resume:
        'https://doc-0g-6c-docs.googleusercontent.com/docs/securesc/0ie7hmun9rc286ofgj8ua7mdd9488q1q/2p73unq3eo76s1upvt850obsm722v205/1601025525000/02493656482600388884/02493656482600388884/1BLm5MM7voMsmNrqxnPGBpS6oinChs4Ky?e=download&authuser=0&nonce=fsjsa12a7iqnc&user=02493656482600388884&hash=nt0fqg9l96ui4ksrp7quagf9p29aatlm', */
      phoneNumber: '+917569984640',
      email: 'kirankumar225rk@gmail.com',
      address: 'Hyderabad, Telangana, India',
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/kmunna2',
      linkedIn: 'https://www.linkedin.com/in/kirankumar-gonti-813870137/',
      github: 'https://github.com/kirankumargonti',
      codePen: 'https://codepen.io/kirankumargonti',
      instagram: 'https://www.instagram.com/kirankumar_gonti57/',
      hackerRank: 'https://www.hackerrank.com/kirankumar225rk',
      youtube: 'https://www.youtube.com/c/kirankumargonti',
      twitter: 'https://twitter.com/gontikirankumar',
    },
    youtubeVideos: [
      {
        youtubeURL: 'https://www.youtube.com/c/kirankumargonti',
        youtubeVideosGithubRepo: 'https://github.com/kirankumargonti/youtube',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/4vXrlhON6Zw/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLApNP_ZE1AbPXHbMDhx8WYOWgKvTg',
        videoTitle: 'Build a Google Clone Design ',
        videoURL: 'https://www.youtube.com/watch?v=4vXrlhON6Zw&t=130s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/NeHqamXt7Pk/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCXbwIg2Vz5uqYypvzeZwcFapSpMA',
        videoTitle: 'Build A Responsive Stock Photos Website Landing Page',
        videoURL: 'https://www.youtube.com/watch?v=NeHqamXt7Pk&t=955s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/E3pa_P4fYbc/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCnHT8_lKAiI3mlg_mzSP_z8ZWf0g',
        videoTitle:
          'Create A Responsive Side Navigation Bar | Vertical Navigation',
        videoURL: 'https://www.youtube.com/watch?v=E3pa_P4fYbc&t=86s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/faWtSiAnwcY/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCLm05b_5VoCR7q9EYFqi3Rkbg4DA',
        videoTitle: 'Create A Fullscreen Video Background',
        videoURL: 'https://www.youtube.com/watch?v=faWtSiAnwcY&t=86s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/2GBQs7FV7Ro/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLBi4zNksq5VLd9q0UIKxHSw5Ews8A',
        videoTitle: 'Create A Responsive Sign Up Form',
        videoURL: 'https://www.youtube.com/watch?v=2GBQs7FV7Ro&t=37s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/fakVrbVTpXs/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLAtQ2QrI-zHO19y6xJ_B85rVaS7ww',
        videoTitle: 'Create A Coming Soon | Website is Under Construction Page',
        videoURL: 'https://www.youtube.com/watch?v=fakVrbVTpXs&t=134s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/SrtjXxxMb4U/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLAGXBe8PVl7OC57V0jk1-POcxYp7g',
        videoTitle: 'Animated Image Slider With Zoom Out Effect ',
        videoURL: 'https://www.youtube.com/watch?v=SrtjXxxMb4U',
      },

      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/m-moQwfysdM/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLC2P2rK_2PFP57F0bTDKne876EYBg',
        videoTitle: 'Simple Responsive Countdown Timer With JavaScript',
        videoURL: 'https://www.youtube.com/watch?v=m-moQwfysdM&t=114s',
      },
      {
        videoID: uuid(),
        videoThumbnail:
          'https://i.ytimg.com/vi/U2yJHf6XogM/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAR9pJI0b8yKTZADnhkEk7Xy6g3aQ',
        videoTitle: 'How To Make A Swing Effect With HTML, CSS & SASS',
        videoURL: 'https://www.youtube.com/watch?v=U2yJHf6XogM',
      },
      {
        videoID: uuid(),
        videoThumbnail: you1,
        videoTitle: 'Create a Pricing card With HTML CSS and SASS',
        videoURL: 'https://www.youtube.com/watch?v=53aQ4T00iNg',
      },
      {
        videoID: uuid(),
        videoThumbnail: you2,
        videoTitle: 'Create a Pricing card With HTML CSS and SASS',
        videoURL: 'https://www.youtube.com/watch?v=Zh20NG8TmVU',
      },
      {
        videoID: uuid(),
        videoThumbnail: you3,
        videoTitle: 'Responsive Feature Cards With HTML CSS and SASS',
        videoURL: 'https://www.youtube.com/watch?v=iM_QCvn__nM',
      },
    ],
    projects: [
      {
        projectID: uuid(),
        projectImage:
          'https://github.com/kirankumargonti/code-sharing-app/raw/main/public/note-code.png',
        projectName: 'Note Code - Code sharing app',
        projectDescription:
          'It is a code sharing platform where you can share your code with others and also you can learn from others code',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/code-sharing-app',
          previewURL: 'https://code-sharing-app-ecru.vercel.app/',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Uniswap Clone',
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot%202024-09-15%20at%2013-27-49%20Uniswap%20Interface_Kg1BPPcqFx.png?updatedAt=1726387192335',
        projectDescription:
          'Uniswap is a decentralized cryptocurrency exchange that uses a set of smart contracts to execute trades on its exchange.',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/uniswap-clone',
          previewURL: 'https://uniswap-clone-mu.vercel.app/',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Pomodoro Timer',
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot%202024-09-15%20at%2013-39-03%20Pomodoro%20Timer_vLFsV25OR.png?updatedAt=1726387752557',
        projectDescription:
          'A simple responsive Pomodoro Timer with start, stop, reset and custom timer functionality.',

        projectLinks: {
          github: 'https://github.com/kirankumargonti/pomodoro_timer',
          previewURL: 'https://kirankumargonti.github.io/pomodoro_timer/',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Agrikol - Landing page',
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot%202024-09-15%20at%2013-36-23%20Flyers_nGIXko6wt.png?updatedAt=1726387593985',
        projectDescription:
          'Agrikol is a landing page for a farming. It is a responsive website built with React.js and Bootstrap.',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/agrikol-landing-page',
          previewURL: 'https://kirankumargonti.github.io/agrikol-landing-page/',
        },
      },

      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot%202024-09-15%20at%2014-07-38%20Game%20of%20Laughs_t6AMq5YTM.png?updatedAt=1726389471626',
        projectName: 'Game of Laughs',
        projectDescription:
          'A Meme to Earn platform that rewards their users for their time and efforts',
        projectLinks: {
          previewURL: 'https://www.gameoflaughs.com/',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot_2022-09-13_at_21-15-23_UnicusOne_-_World_s_first_Multi_Chain_WaaS_RlhU2iUWc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663084684946',
        projectName: 'Unicus One',
        projectDescription:
          'Worlds first Multi Chain WaaS (Web3 as a Service) Platform for Metaverse, Gaming, and NFT Economy',
        projectLinks: {
          previewURL: 'https://unicus.one/',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot_2022-09-13_at_21-33-17_Jungle_toh5lLq2J.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1663085057325',
        projectName: 'Jungle - NFT',
        projectDescription:
          'Worlds Largest NFT Marketplace built on Ethereum and polygon',
      },
      {
        projectID: uuid(),
        projectName: 'Wallet Connect With Rainbowkit and SIWE',
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot%202024-09-15%20at%2013-33-36%20_E0nokmNrQ.png?updatedAt=1726387425656',
        projectDescription:
          'NFT Mint app with multiple wallet support using rainbow kit with sign in with ethereum.',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/wallet-connect-with-rainbowkit-siwe',
          previewURL: 'https://wallet-connect-with-rainbowkit-siwe.vercel.app/',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Pixabay Clone',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/Image-and-video-finder-with-PixaBay-API-and-React/master/demo.jpg',
        projectDescription:
          'React & bootstrap UI application to find images and videos utilizing the PixaBay API',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Image-and-video-finder-with-PixaBay-API-and-React',
          previewURL:
            'https://kirankumargonti.github.io/Image-and-video-finder-with-PixaBay-API-and-React/',
        },
      },

      {
        projectID: uuid(),
        projectName: 'Corona Virus Tracking Application',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/covid19-tracker/master/src/images/demo.png',
        projectDescription:
          'A corona tracker application. Covered topics: React.js, Chart.js, Material UI and much more.',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/covid19-tracker',
          previewURL: 'https://kirankumargonti.github.io/covid19-tracker/',
        },
      },
      {
        projectID: uuid(),
        projectName: '50 Projects in 50 Days',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/50ProjectsIn50Days/main/Expanding_Cards/Screenshot.jpg',
        projectDescription:
          'This repo is contains all web development projects for beginners and intermediate devs',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/50ProjectsIn50Days',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Login authentication with MERN Stack',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/MERN-stack-login-authentication/master/MERN.gif',
        projectDescription:
          'Simple User Registration and Login functionality with MongoDB, ExpressJs, React and NodeJs [MERN]',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/MERN-stack-login-authentication',
        },

        projectTechStack: {
          html: 'html',
          css: 'css',
          mongodb: 'mongodb',
          expressjs: 'expressjs',
          react: 'react',
          nodejs: 'nodejs',
          redux: 'redux',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Nodejs Login App with Passport JS',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/nodejs_passport_login_app/master/public/images/demo.png',
        projectDescription:
          'Node & Express login app with passportjs authentication ',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/nodejs_passport_login_app',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Astra - Landing page',
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot%202024-09-15%20at%2013-57-33%20Astra-%20React%20Test_CXRQ_uhpQF.png?updatedAt=1726388874533',
        projectDescription:
          'Astra is a landing page for a web development course. It is a responsive website built with React.js and Styled components.',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/astra-react-test',
          previewURL: 'https://astra-react-test.vercel.app/',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Dynamic Landing Page With Time-JavaScript',
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot%202024-09-15%20at%2013-50-25%20Time_TIP94zjPe.png?updatedAt=1726388442286',
        projectDescription:
          'landing page with the time that we can interact with it and save our name/focus to local storage.',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Dynamic-Landing-Page-With-Time--Using-HTML-CSS-JavaScript',
          previewURL:
            'https://kirankumargonti.github.io/Dynamic-Landing-Page-With-Time--Using-HTML-CSS-JavaScript/',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Weather Status App With React',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/Weather-Status-App-with-React/master/src/images/demo1.png',
        projectDescription:
          'React Weather Application shows us current weather status of all the cities on the earth',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Weather-Status-App-with-React',
          previewURL:
            'https://kirankumargonti.github.io/Weather-Status-App-with-React/',
        },
        projectTechStack: {
          react: 'react',
          html: 'html',
          css: 'css',
        },
      },
      {
        projectID: uuid(),
        projectName: 'A Responsive Photography Website',
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot%202024-09-15%20at%2013-42-03%20Kiran%20Digital%20Studio%20A%20Multilevel%20Digital%20Studio_Ao1H0MZX44.png?updatedAt=1726387939428',
        projectDescription:
          'A responsive photography website with a modern and clean design. It is built with HTML, CSS, and JavaScript.',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Responsive-Photography-Website',
          previewURL:
            'https://kirankumargonti.github.io/Responsive-Photography-Website//',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Google Chrome Extension',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/Google-Chrome-Extension/master/images/extension_pic.jpg',
        projectDescription:
          'How to create google chrome extension and this is my personal chrome extension which navigates to my social links ',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/Google-Chrome-Extension',
        },
      },
      {
        projectID: uuid(),
        projectName: 'Random Password Generator ',
        projectImage:
          'https://raw.githubusercontent.com/kirankumargonti/Random-Password-Generator-JavaScript/master/image/1.png',
        projectDescription:
          'A Random Password Generator That will take Length, Characters, Numbers and Symbols as an option using JavaScript. ',
        projectLinks: {
          github:
            'https://github.com/kirankumargonti/Random-Password-Generator-JavaScript',
          previewURL:
            'https://kirankumargonti.github.io/Random-Password-Generator-JavaScript/',
        },
      },

      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/cc_nzhjoTBbS2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663083169039',
        projectName: 'Credit Capital',
        projectDescription: `Credit Capital is an uncollateralized lending DeFi
            protocol, powered by TRU. It allows lenders to earn high,
            stable returns on loaned cryptocurrencies`,
        projectLinks: {
          previewURL: 'https://creditcapital.io/',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/crossing_XkIXibvg8F.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663083169035',
        projectName: 'The Crossing',
        projectDescription:
          'This bridging service provides access to inter-blockchain liquidity',
      },

      {
        projectID: uuid(),
        projectImage:
          'https://i.ytimg.com/vi/4vXrlhON6Zw/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLApNP_ZE1AbPXHbMDhx8WYOWgKvTg',
        projectName: 'Google Clone',
        projectDescription:
          'Google Clone With React ( Hooks + Context API ) and Google Custom Search JSON API ( Programmable Search Engine ) ',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/google-clone',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/amazon_9KK7KsVU6r.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663083168733',
        projectName: 'Amazon Clone',
        projectDescription:
          'Amazon clone app with React ( Hooks + Context API ) and Firebase Authentication ',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/amazon-clone',
          previewURL: 'https://clone-ddfd4.web.app/',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot_2022-09-13_at_21-15-08__20___Facebook_WlGgbk3Zw.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663084685558',
        projectName: 'Facebook Clone',
        projectDescription:
          'Facebook Clone With React( Hooks + Context API) and Firebase Authentication',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/facebook-clone',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/tiktok_oOdqzDdI9.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663084905016',
        projectName: 'TikTok Clone',
        projectDescription:
          'TikTok Clone app with MERN ( MongoDB Express React Node ) Stack',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/tiktok-clone',
          previewURL: 'https://tiktok-clone-mern-b8cd7.web.app/',
          download:
            'https://codeload.github.com/kirankumargonti/tiktok-clone/zip/master',
        },
        projectTechStack: {
          react: 'react',
          sass: 'sass',
          firebase: 'firebase',
          mongodb: 'mongodb',
          expressjs: 'expressjs',
          nodejs: 'nodejs',
        },
      },
      {
        projectID: uuid(),
        projectImage:
          'https://ik.imagekit.io/kirankumargonti/projects/Screenshot_2022-09-13_at_9.15.56_PM_cHlbKUqi8.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663084698701',
        projectName: 'Zoom Clone',
        projectDescription:
          'Zoom Clone with Node Js, Express Js, Socket.io, Peer Js and ejs',
        projectLinks: {
          github: 'https://github.com/kirankumargonti/zoom-clone',
        },
      },

      /* end */
    ],
    timeline: [
      {
        title: 'Frontend Developer',
        description: 'Software Developer',
        dateOfJoining: 'May 01, 2019',
        end: 'May 30, 2021',
        companyName: 'Avyuktha LTD',
        location: 'Limassol, Cyprus - (Remote)',
      },
      {
        title: 'UI/UX Designer and Developer',
        companyName: 'Adobe Creative Technologies',
        duration: 'July 2018 - No expiration date',
        location: 'Guru Nanak Institute of Technology',
      },
      {
        title: 'Bachelor of Technology',
        stream: 'Computer science and engineering',
        duration: '2015 July - 2019 April',
        collegeName: 'Guru Nanak Institute of Technology - Hyderabad',
      },
      {
        title: 'Technical Lead (Frontend Developer - web3)',
        description: 'Software Developer',
        duration: '2021 June - 2023 Jan',
        companyName: 'CodeZero2Pi Pvt Ltd',
        location: 'Noida, Uttar Pradesh, India - (Remote)',
      },
      {
        title: 'Frontend Developer- SDE - 2',
        description: 'Software Developer',
        dateOfJoining: [2023, 1, 1, 0, 0, 0],
        companyName: 'Virtual Internships',
        location: 'London, England, United Kingdom - (Remote)',
      },
    ],
  },
}

export default data
