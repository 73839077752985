import React from 'react';
import {SiGmail} from 'react-icons/si';
import {FcHome, FcPhoneAndroid} from 'react-icons/fc';
import SocialLink from '../Components/SocialLinks';
import ContactForm from './ContactForm';

// DATA
import data from '../Data/data';

function Contact() {
  const {
    email,
    address,
    phoneNumber,
  } = data.kirankumargonti.personalInformation;
  return (
    <section className='contact' id='contact'>
      <div className='section_title'>
        <h1>Contact</h1>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-md-12 col-sm-12 mb-5'>
            <div className='contact_left'>
              <div className='contact_left_inner'>
                <div className='contact_social_links'>
                  <h2>I'M ALSO ON SOCIAL NETWORKS</h2>
                  <SocialLink />
                </div>
                <div className='home'>
                  <FcHome className='icon' />
                  <div>
                    <h4>Address</h4>
                    <p>{address}</p>
                  </div>
                </div>
                <div className='phone'>
                  <FcPhoneAndroid className='icon' />
                  <div>
                    <h4>Let's Talk</h4>
                    <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                  </div>
                </div>
                <div className='mail'>
                  <SiGmail className='icon' />
                  <div>
                    <h4>E-Mail</h4>
                    <a href={`mailto:${email}`}>
                      {email}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 mb-5'>
            <div className='contact_right'>
              <h2>Get in touch</h2>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
