import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {ReactLenis} from 'lenis/react'

// Pages
import Home from './Pages/Home'
import Projects from './Pages/Projects'
import NotFound from './Pages/NotFound'

// Components
import Footer from './Components/Footer'
import ArrowUp from './Components/ArrowUp'
import ScrollRestoration from './Components/ScrollRestoration'
import ProgressBar from './Components/ProgressBar'
// import Loading from './Components/Loading'
import AnimatedGrid from './Components/AnimatedGrid'
import Header from './Components/Header'

function App() {
  return (
    <ReactLenis root>
      <div className='app'>
        {/* <AnimatedGrid /> */}
        <BrowserRouter>
          {/* <Loading /> */}
          <ProgressBar />
          <Header />
          {/* <StickyHeader /> */}
          <Routes>
            <Route
              path='/'
              element={
                <>
                  <ScrollRestoration />
                  <Home />
                </>
              }
            />
            <Route
              path='/projects'
              element={
                <>
                  <ScrollRestoration />
                  <Projects />
                </>
              }
            />
            <Route
              path='*'
              element={
                <>
                  <ScrollRestoration />
                  <NotFound />
                </>
              }
            />
          </Routes>
          <ArrowUp />
          <Footer />
        </BrowserRouter>
      </div>
    </ReactLenis>
  )
}

export default App
